/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/


/*3.1 wpo-hero-slider*/

.hero {
	position: relative;
	height: 100vh;

	@include media-query(767px) {
		min-height: 420px;
	}

	.slide {
		height: 100vh;
		position: relative;

		@include media-query(767px) {
			min-height: 420px;
		}

		background-repeat: no-repeat;
		position: relative;

		.slider-bg {
			display: none;
		}
	}

	.slide:focus {
		outline: none;
	}

	.slide .container {
		height: 100%;
		display: table;
	}

	.slide .row {
		display: table-cell;
		vertical-align: middle;
	}


	/** slider controls **/
	.slick-prev,
	.slick-next {
		background-color: transparentize($theme-primary-color, 0.7);
		width: 55px;
		height: 55px;
		z-index: 10;
		border-radius: 50%;
		@include transition-time(0.5s);

		&:hover {
			background-color: transparentize($theme-primary-color, 0.4);
		}

		@include media-query(991px) {
			display: none !important;
		}
	}

	.slick-prev {
		left: -100px;

		&:before {
			font-family: "themify";
			content: "\e629";
			opacity: 1;
		}
	}

	.slick-next {
		right: -100px;

		&:before {
			font-family: "themify";
			content: "\e628";
			opacity: 1;
		}
	}

	&:hover .slick-prev {
		left: 25px;
	}

	&:hover .slick-next {
		right: 25px;
	}

	.slick-dots {
		bottom: 30px;

		@include widther(992px) {
			display: none !important;
		}

		li {
			margin: 0;
		}

		button {
			background-color: $theme-primary-color;
			width: 14px;
			height: 14px;
			border: 2px solid $white;
			border-radius: 50%;
		}

		button:before,
		button:before {
			display: none;
		}
	}


	/*** hero slider animation ***/
	.slide-caption>div {
	
	}

	.slide-caption>div * {
		-webkit-animation: fadeOutLeft 1.5s both;
		animation: fadeOutLeft 1.5s both;
	}

	.hero-right-text  {
		-webkit-animation: fadeOutRight 1.5s both;
		animation: fadeOutRight 1.5s both;
	}

	.slide-caption>div.slide-title * {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}


	.slide-caption>div.btns * {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}

	.hero-slider .slick-current .slide-caption>div * {
		-webkit-animation-name: fadeInLeft;
		animation-name: fadeInLeft;
	}

	.hero-slider .slick-current .hero-right-text  {
		-webkit-animation-name: fadeInRight;
		animation-name: fadeInRight;
	}

	.hero-slider .slick-current .slide-caption>div.slide-title * {
		-webkit-animation-delay: 0.5s;
		animation-delay: 0.5s;
	}


	.hero-slider .slick-current .slide-caption>div.btns * {
		-webkit-animation-delay: 1.5s;
		animation-delay: 1.5s;
	}

	.hero-slider .slick-current .hero-right-text  {
		-webkit-animation-delay: 2s;
		animation-delay: 2s;
	}
}



/************************************************
  hero-style-1
**************************************************/
.hero-style-1 {
	max-height: 800px;
	position: relative;
	overflow: hidden;


	@include media-query(1199px) {
		max-height: 750px;
	}

	@include media-query(991px) {
		max-height: 650px;
	}

	@include media-query(767px) {
		max-height: 600px;
	}

	@include media-query(575px) {
		max-height: 500px;
	}

	.slide {
		max-height: 800px;
		position: relative;
		overflow: hidden;
		z-index: 1;

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: '';
			background: $text-color;
			opacity: 0.5;
			z-index: -1;
		}

		@include media-query(1199px) {
			max-height: 750px;
		}

		@include media-query(991px) {
			max-height: 650px;
		}

		@include media-query(767px) {
			max-height: 600px;
		}

		@include media-query(575px) {
			max-height: 500px;
		}

		.row {
			position: relative;
		}

		.slide-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -11;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.slide-caption {
		padding-top: 10px;
	}

	.slide-caption h2 {
		color: $white;
		margin-bottom: 15px;
		font-family: $heading-font;
		font-size: 90px;
		font-weight: 400;
		line-height: 95px;

		@include media-query(1200px) {
			font-size: 50px;
			font-size: calc-rem-value(50);
			line-height: 60px;
		}

		@include media-query(991px) {
			font-size: 45px;
			font-size: calc-rem-value(45);
			line-height: 50px;
		}

		@include media-query(767px) {
			font-size: 35px;
			font-size: calc-rem-value(35);
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 32px;
			font-size: calc-rem-value(32);
		}
	}

	.slide-caption p {
		color: $white;
		font-family: $base-font;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 29px;
		line-height: 35px;
		padding-right: 105px;

		@include media-query(1200px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
			padding-right: 0;
			line-height: 25px;
		}

		@include media-query(991px) {
			margin: 0 0 1.8em;
			font-size: 15px;
			line-height: 20px;
		}
	}

	 .hero-right-text {
		width: 480px;
		background: $theme-primary-color;
		padding: 40px 30px;
		align-items: center;
		border-left: 7px solid $white;
		position: absolute;
		bottom: 0%;
		right: -24%;
		@media (max-width:992px) {
			padding: 20px;
			width: 350px;
		}

		@media (max-width:767px) {
			padding: 10px;
			width: 380px;
		}

		@media (max-width:425px) {
			padding: 10px;
			width: 310px;
		}
		@media (max-width:389px) {
			display: none;
		}

		.grid {

			.info {
				float: left;
				width: 40%;
				position: relative;
				z-index: 1;

				&::before {
					position: absolute;
					left: -16px;
					top: 18px;
					width: 80px;
					height: 80px;
					border-radius: 50%;
					content: '';
					background: rgba(255, 255, 255, 0.13);
					z-index: -1;

					@media (max-width:767px) {
						left: 0;
						top: 25px;
					}

					@media (max-width:425px) {
						left: 10px;
						top: 30px;
						width: 60px;
						height: 60px;
					}
				}

				h3 {
					font-size: 130px;
					font-weight: 400;
					line-height: 158px;
					color: $white;
					margin: 0;

					@media (max-width:767px) {
						font-size: 90px;
					}

					@media (max-width:425px) {
						font-size: 75px;
						line-height: 90px;
					}

					.odometer-inside {
						font-family: $heading-font;
					}
				}
			}

			.text {
				float: left;
				width: 60%;
				padding-right: 30px;
				padding-top: 15px;

				@media (max-width:767px) {
					padding: 0;
				}

				h4 {
					font-family: $heading-font;
					font-size: 30px;
					font-weight: 400;
					line-height: 36px;
					color: $white;
					margin-bottom: 13px;

					@media (max-width:425px) {
						font-size: 20px;
						margin-bottom: 0;
					}
				}

				span {
					font-family: $base-font;
					font-size: 16px;
					font-weight: 400;
					line-height: 26px;
					color: $white;
					margin: 0;
				}
			}
		}
	}
}

/************************************************
  wpo-hero-slider
**************************************************/

.wpo-hero-slider,
.static-hero,
.static-hero-s2,
.static-hero-s3,
.wpo-hero-slider-s2 {
	width: 100%;
	height: 900px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		// z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		&:before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #040128;
			content: "";
			opacity: .6;
			z-index: -1;
		}

		.slide-content{
			padding-top: 80px;

			@media(max-width:1700px){
			}
			@media(max-width:991px){
			  padding-top: 35px;
			}
			@media(max-width:767px){
			}
		}
	}

	// slider controls
	.swiper-button-prev,
	.swiper-button-next {
		background: transparentize($white, 0.8);
		width: 60px;
		height: 60px;
		line-height: 60px;
		border: 2px solid $white;
		border-radius: 50%;
		opacity: 0.7;
		text-align: center;
		@include transition-time(0.3s);

		&:hover {
			opacity: 0.9;
		}

		@include media-query(767px) {
			display: none;
		}
	} 

	.swiper-button-prev {
		left: 25px;

		&::after {
			font-family: "themify";
			content: "\e629";
		    font-size: 20px;
		    color: #fff;
		}
	}

	.swiper-button-next {
		right: 25px;

		&::after {
			font-family: "themify";
			content: "\e628";
		    font-size: 20px;
		    color: #fff;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		@include media-query(991px) {
			display: none;
		}
	}

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
		font-size: 12px;
		color:#000;
		opacity: 1;
		background: rgba(255,255,255,0.2);

		@include widther(992px) {
			display: none;
		}
	}

	.swiper-pagination-bullet-active {
		color:#fff;
		background: $white;
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets, 
	.swiper-pagination-custom, .swiper-pagination-fraction {
		bottom: 30px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	    margin: 0 8px;
	}
}


.wpo-hero-slider,
.static-hero,
.static-hero-s2,
.static-hero-s3,
.wpo-hero-slider-s2{

	@include media-query(1199px) {
		height: 680px;
	}

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 500px;
	}



	.swiper-slide {
		position: relative;
		z-index: 11;
	}

	.wpo-hero-title-top{
		span{
			font-size: 22px;
			color: #e4e4e4;
			font-family: $heading-font;

			@include media-query(767px) {
				font-size: 15px;
			}
	
		}
	}

	.slide-title-sub{
		h5{
			font-size: 19px;
			color: $white;
			font-family: $base-font;
			text-transform: uppercase;
			font-weight: 400;
			letter-spacing: 2px;
			margin-bottom: 30px;

			@include media-query(1200px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}

			@include media-query(991px) {
				margin-bottom: 20px;
			}
		}
	}

	.slide-title {
		max-width: 712px;

		@include media-query(1199px) {
			max-width: 555px;
		}


		h2 {
			font-size: 70px;
			font-weight: 400;
			line-height: 90px;
			margin:10px 0 15px;
			color: $white;
			text-transform: capitalize;

			@include media-query(1199px) {
				font-size: 50px;
				font-size: calc-rem-value(50);
				line-height: 65px;
				margin-top: 0;
			}

			@include media-query(991px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				line-height: 55px;
			}

			@include media-query(767px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
				line-height: 36px;
			}
		}
	}

	.slide-text {
		max-width: 680px;

		@include media-query(767px) {
			max-width: 500px;
		}
		
		p {
			font-size: 22px;
			color: $white;
			line-height: 35px;
			max-width: 680px;
			margin-bottom: 40px;
			color: black;
			background-color: #c2cfd0;
			border-radius: 6px;
			padding: 10px;
			opacity: 0.6;

			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
				line-height: 22px;
				margin-bottom: 30px;
			}
		}		
	}

	.slide-btns {
		@include media-query(991px) {
			max-width: 400px;
		}

		.theme-btn{
			border-radius: 0;
			font-weight: 600;
			font-size: 18px;

			&:after{
				border-radius: 0;
			}
		}
	}


	.slide-btns .hero-video-btn {
		margin-left: 40px;

		@include media-query(767px) {
			margin-left: 20px;
		}
	}
}
// static-hero

.static-hero{
	z-index: 1;
    background: url(../../images/slider/slide-4.jpg) no-repeat center center;
	overflow: hidden;

	.hero-inner{

		&:before{
			z-index: -1;
		}
	}
	.hero-content {
		text-align: center;
		margin-top: -20px;
	}
	.slide-title{
		max-width: 780px;
		margin: auto;

		@include media-query(1199px) {
			max-width: 550px;
		}
		@include media-query(575px) {
			max-width: 100%;
		}

		h2{
			text-transform: capitalize;
			font-weight: 500;
			line-height: 100px;
			@include media-query(1199px) {
				line-height: 60px;
			}
			@include media-query(767px) {
				line-height: 40px;
			}
		}
	}
	.slide-text{
		margin: auto;
		max-width: 750px;
	}
	.btns{
		.theme-btn{
			border-radius: 0;
			font-weight: 600;
			font-size: 18px;

			&:after{
				border-radius: 0;
			}
		}
	}
}