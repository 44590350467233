/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



// fonts
$base-font-size: 15px;
$base-font: 'Roboto-Regular', regular;
$heading-font: 'Roboto-Regular', regular;



// color
$dark-gray: #070143;
$body-color: #182538;//
$white: #fff;//
$light: #c2cfd0;
$black: #000;//
$small-black:#c2cfd0;//
$cyan:#c2cfd0;

$theme-primary-color:#46a79c;//
$theme-primary-color-s2:#fff;//
$body-bg-color: #fff;//
$section-bg-color: #687693;//
$text-color: #0D0925;//
$text-color-s2: #484653;//
$text-light-color:#66717A;//
$heading-color: $dark-gray;
$border-color: #F1EAE5;//
$border-color-s2: #E0D8CE;//
$border-color-s3:  #EDE4DE;//
